import {post} from '@/utils/axios'

// 成绩汇总
export const academicList = params => post('/shida-user-biz/TbExam/getClassEduInfo', params)

export const academicByUserId = params => post('/shida-user-biz/TbExam/getEduInfo', params)

export const addRaceGroup = params => post('/shida-user-biz/TbMatch/saveOrUpdateBatchMatch', params)

// 学科比赛
export const addProjectRace = params => post('/shida-user-biz/TbMatch/saveOrUpdateSubjectMatch', params)

export const getProjectRaceById = params => post('/shida-user-biz/TbMatch/getUserSubjectMatchList', params)

export const getProjectRace = params => post('/shida-user-biz/TbMatch/getClassSubjectMatchList', params)

export const getProjectRaceDetail = params => post('/shida-user-biz/TbMatch/getSubjectMatchDetail', params)

export const removeProjectRace = params => post('/shida-user-biz/TbMatch/deleteSubjectMatch', params)

// 平时成绩
export const getUsualRecord = params => post('/shida-user-biz/TbExam/getCommonExamListByTeacher', params)

export const getUsualResult = params => post('/shida-user-biz/TbExam/getExamScore', params)

export const getUsualResultById = params => post('/shida-user-biz/TbExam/getHomeCommonScore', params)

// 期中成绩
export const getMidResult = params => post('/shida-user-biz/TbExam/getExamScore', params)

export const getMidResultById = params => post('/shida-user-biz/TbExam/getUserMiddleExamScore', params)

export const importMidResult = params => post('/shida-user-biz/TbExam/saveMiddleExamScoreByExcel', params)

// 期末成绩
export const getFinalResult = params => post('/shida-user-biz/TbExam/getExamScore', params)

export const getFinalResultById = params => post('/shida-user-biz/TbExam/getUserFinalExamScore', params)

export const importFinalResult = params => post('/shida-user-biz/TbExam/saveFinalExamScoreByExcel', params)

// 修改成绩
export const updateResult = params => post('/shida-user-biz/TbExam/updateExamScore', params)

export const getIndexResult = params => post('/shida-user-biz/TbExam/getExamScoreInfo', params)

// 课堂表现评价
export const getEvaluate = params => post('/shida-user-biz/TbShowevalTeacher/getData',params)

export const saveEvaluate = params => post('/shida-user-biz/TbShowevalTeacher/saveOrUpdate',params)

export const updateEvaluate = params => post('/shida-user-biz/TbShowevalTeacher/updateChangeTeacherEval',params)

export const getEvaluateById = params => post('/shida-user-biz/TbShowevalSelf/getUserEvalInfo',params)

export const getEvaluateDetail = params => post('/shida-user-biz/TbShowevalOther/getList',params)

// 学科比赛审核
export const acMatchAudit = params => post('/shida-user-biz/TbMatch/updateAuditStatus', params)

// 判断显示平时、期中、期末成绩
export const showTestTp = params => post('/shida-user-biz/TbAcademicScore/getAcademicExamByTerm', params)
