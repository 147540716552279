<template>
  <div class="web-content container">
    <div class="user-nav">
      <Search ref="search" :term="false" :classlist='classlist' @handleQuery='handleQuery' />
    </div>
    <div class="main">
      <div>
        <h4>
          测试名称：{{paramsData.examName}}
          <span style="margin-left: 20px">年级：{{paramsData.examGrade}}级</span> 
        </h4>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="tableHeaderColor"
        :cell-style="cellStyleFun"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="userName" label="学生姓名">
        </el-table-column>
        <el-table-column prop="loginName" label="学籍号" width="180">
        </el-table-column>
        <el-table-column prop="className" label="班级" width="120">
        </el-table-column>
        <el-table-column v-for="(item,index) in titleList" :key="index" :prop="item.code" :label="item.name"> </el-table-column>
        <!-- <el-table-column prop="userExamIndexScore" label="综素成绩">
        </el-table-column> -->
      </el-table>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />

      <div class="back">
        <el-button type="text" @click="backIndex">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search";
import {getUsualResult} from '@/api/teacher/quality/Academic'
export default {
  data() {
    return {
      tableData: [],
      titleList: [],
      typeId: '309',
      total: 0,
      queryParams: {
        examType: '1',
        pageSize: 100,
      },
      paramsData: {},
      classlist: [],
    };
  },
  components: { Search },
  created() {
    this.paramsData = this.$route.params.userName != undefined ? this.$route.params : JSON.parse(sessionStorage['params'])
    this.queryParams.classId = this.paramsData.classId
    this.queryParams.examId = this.paramsData.examId
    this.getFirst()
  },
  methods: {
    getFirst() {
      this.$store.dispatch('GetClassList', this.typeId).then(res=>{
        this.classlist = res.data
        this.queryParams.termName = this.$store.getters.queryForm.termName
        this.$refs.search.init(this.queryParams.termName,this.queryParams.classId)
        this.getList()
      })
    },
    getList() {
      getUsualResult(this.queryParams).then(res=> {
        this.tableData = res.data.content
        this.titleList = res.data.title
      })
    },
    handleQuery(data) {
      this.queryParams.classId = data.deptId
      this.queryParams.userNameOrLoginName = data.userName
      this.getList()
    },
    backIndex() {
      this.$router.push({name: "Academic", params: {activeName: '1'}});   
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color: #F6F7FA;color: #616266;font-weight: blod;text-align:center";
      }
    },
    cellStyleFun() {
      return "text-align:center";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/el-tabs.scss";
@import "@/assets/scss/studentResult.scss";
.container {
  .user-nav {
    padding: 10px 15px;
  }
}
</style>